.card .card-body {
    padding: 25px !important;
  }
  .navbar .navbar-brand-wrapper .navbar-brand  img {
  width: unset !important;
  height: unset !important;
}
.navbar .navbar-brand-wrapper .navbar-brand.brand-logo-mini img {
  min-width: 32px!important;
  min-height: 32px!important;
  width: unset!important  ;
  
}
.tag-type{
  display: flex!important;
  flex-direction: row!important;
  justify-content: flex-start;
  margin-bottom: 10px;
  
}
.form-dropDown-icon{
  font-size: 30px!important;
  color: #5E50F9!important;
} 

.icon-div
{
  min-width: 55px;
  justify-content: center;
  /* parent div of icons in input box in tagform component */
}

.MuiSwitch-root{
  float: right!important;
  margin-top: -6px;
  /* toggle switch for tags */
}

